import axios from "axios";
import { isAuthenticated } from "./auth";
import constants from "../constants";

const api = async (method, urlEndPoint, data = {}, params = {}) => {
    try {
        let headers = {
            "Content-Type": "application/json",
        };
        if (isAuthenticated()) {
            headers = {
                ...headers,
                Authorization: `Bearer ${isAuthenticated()}`,
            };
        }

        let response = await axios({
            method,
            url: constants.endPointUrl + urlEndPoint,
            data,
            headers,
            params,
        });

        let res = response.data;

        return res;
    } catch (error) {
        console.log(error);
        let res = error?.response ? error.response.data : error.toString();
        return res;
    }
};

export default api;
